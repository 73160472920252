import { useCallback, useEffect, useRef, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { moveArrayItem } from '../modules/common';
import { Box, IconButton, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { JoinedTable, Select } from '../common/types';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { FormikErrors } from 'formik';
import CancelIcon from '@mui/icons-material/Cancel';

enum ITEM_TYPE {
  CARD = 'Card',
}

export const DndWrapper: React.FC<{
  index: number;
  children: React.ReactNode;
  onMoveItem: (dragIndex: number, hoverIndex: number) => void;
  onDropEnd?: () => void;
}> = ({ children, onMoveItem, index, onDropEnd }) => {
  const ref = useRef<HTMLDivElement>(null);

  /**
   * define drop function from react-dnd
   * when user drag an element and hover to another element,
   * hover function will call and calculate new index for element then wap it
   */
  const [, drop] = useDrop<{ index: number }>({
    accept: ITEM_TYPE.CARD,
    hover(item, monitor) {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) return;

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset() ?? { x: 0, y: 0 };
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (
        (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) ||
        (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)
      )
        return;

      onMoveItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },

    drop: () => {
      onDropEnd?.();
    },
  });

  /**
   * define drag function from react-dnd
   * @param: isDragging => tracking when element is dragging
   */
  const [{ isDragging }, drag, preview] = useDrag<{ index: number }, null, { isDragging: boolean }>(
    {
      item: { index },
      type: ITEM_TYPE.CARD,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }
  );

  // register div element with ref to Drag and Drop monitor
  preview(ref);
  drop(ref);

  const opacity = isDragging ? 0 : 1;

  return (
    <Stack
      direction={'row'}
      ref={ref}
      sx={{
        opacity,
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: '5px',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        p: 1,
        mt: 1,
      }}
    >
      <Box ref={drag} sx={{ cursor: 'move', mt: 1, mr: 1 }}>
        <DragIndicatorIcon />
      </Box>
      {children}
    </Stack>
  );
};

export const DndContainer: React.FC<{
  data: Select[];
  joinedTables: JoinedTable[];
  errors: string | string[] | FormikErrors<Select>[] | undefined;
  deleteFunc: (index: number) => void;
  onDropEnd?: (data: Select[]) => void;
  onBlur: (event: React.FocusEvent<any>) => void;
}> = ({ data: dataP, joinedTables, errors, deleteFunc, onDropEnd, onBlur }) => {
  const [data, setData] = useState<Select[]>([]);

  const onMoveItem = useCallback((drag: number, hover: number) => {
    setData((s) => {
      return moveArrayItem(s, drag, hover);
    });
  }, []);

  const updateAlias = (value: string, index: number) => {
    setData((prev) => {
      const newData = [...prev];
      newData.forEach((e, i) => {
        if (i === index) {
          e.alias = value;
        }
      });
      return newData;
    });
  };

  useEffect(() => {
    setData(dataP);
  }, [dataP]);

  return (
    <DndProvider backend={HTML5Backend}>
      {data.length
        ? data.map((row, index) => {
            const table = joinedTables.find((e) => e.tableUuid === row.tableUuid);
            const column = table?.columns?.find((e) => e.columnUuid === row.columnUuid);
            const prevIndex = dataP.findIndex((sel) => sel.columnUuid === row.columnUuid);
            return (
              <DndWrapper
                onDropEnd={() => onDropEnd?.(data)}
                index={index}
                onMoveItem={onMoveItem}
                key={`drag_drop_${row.columnUuid}`}
              >
                <Stack
                  direction={'row'}
                  sx={{
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                  }}
                >
                  <Typography sx={{ fontWeight: 'bold', wordBreak: 'break-word', mt: 1, mr: 2 }}>
                    {`${table?.alias ?? `${table?.schemaN}.${table?.tableN}`}.${column?.name} (${column?.dataType.type})`}
                  </Typography>

                  <Stack
                    direction={'row'}
                    sx={{
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Typography sx={{ mt: 1, width: 100 }}>カラム別名</Typography>

                    <TextField
                      id={`simple.select[${index}].alias`}
                      name={`simple.select[${index}].alias`}
                      value={row.alias ?? ''}
                      onChange={(e) => updateAlias(e.target.value, index)}
                      onBlur={onBlur}
                      error={
                        prevIndex !== -1 &&
                        Boolean(
                          errors &&
                            typeof errors[prevIndex] !== 'string' &&
                            (errors[prevIndex] as FormikErrors<Select>)?.alias
                        )
                      }
                      helperText={
                        prevIndex !== -1 &&
                        errors &&
                        typeof errors[prevIndex] !== 'string' &&
                        (errors[prevIndex] as FormikErrors<Select>)?.alias
                      }
                      size="small"
                      sx={{ width: 340 }}
                    />
                    <Tooltip title="削除" placement="top">
                      <IconButton
                        size={'small'}
                        sx={{ color: '#5F6368', mt: 0.3 }}
                        onClick={() => deleteFunc(index)}
                      >
                        <CancelIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Stack>
              </DndWrapper>
            );
          })
        : null}
    </DndProvider>
  );
};
