import { Cancel, EditOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Popover,
  PopoverActions,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
  colors,
} from '@mui/material';
import { FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { convertOrderByToString, shortenColumnName } from '../../common/func/functions';
import { MAX_COLUMN_TEXT_LENGTH } from '../../common/const/maxTextLength';
import { JoinedTable, OrderBy, SimpleQueryForm } from '../../common/types';

type Props = {
  formik: FormikProps<SimpleQueryForm>;
  joinedTables: JoinedTable[];
  isColumnLoading: boolean;
};

const OrderByConditionParts: React.FC<Props> = ({
  formik,
  joinedTables,
  isColumnLoading,
}: Props) => {
  const [conditionSettingDialogOpen, setConditionSettingDialogOpen] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState<{
    tableUuid: string;
    columnUuid: string;
    name: string;
    type: string;
  }>({
    tableUuid: '',
    columnUuid: '',
    name: '',
    type: '',
  });
  const [orderValue, setOrderByValue] = useState<'asc' | 'desc'>('asc');
  // target to show popover
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const popoverAction = useRef<PopoverActions>(null);

  const [search, setSearch] = useState<string>('');

  const handleUpdatePopoverPosition = () => {
    // find scroll element (main)
    const mainElement = document.querySelector('main.MuiBox-root');
    setTimeout(() => {
      if (mainElement) {
        // get y & height of add button
        const anchorElY = anchorEl?.getBoundingClientRect().y ?? 0;
        const anchorElHeight = anchorEl?.getBoundingClientRect().height ?? 0;
        // calculate distance
        const distance = window.innerHeight - anchorElY - anchorElHeight - 320;

        // if button is over bottom by popup scroll button into view
        if (distance < 0) {
          mainElement.scrollTo({
            top: mainElement.scrollTop - distance,
            behavior: 'smooth',
          });
        }
      }
      setTimeout(() => {
        popoverAction.current?.updatePosition();
      }, 100);
    }, 100);
  };

  const onClickUpdateSettingOrderByCondition = (condition: OrderBy) => {
    onClickConditionSetting(condition.columnUuid);
  };

  // 削除ボタン
  const onClickDeleteOrderByCondition = (condition: OrderBy) => {
    const newConditions = formik.values.simple.orderby.filter(
      (stateCondition) => stateCondition !== condition
    );
    formik.setFieldValue('simple.orderby', newConditions);
  };

  const handleDialogOk = () => {
    if (!selectedColumn.columnUuid) {
      // 初期化して終了
      setSelectedColumn({ tableUuid: '', columnUuid: '', name: '', type: '' });
      setOrderByValue('asc');
      return;
    } else {
      const newCondition: OrderBy = {
        tableUuid: selectedColumn.tableUuid,
        columnUuid: selectedColumn.columnUuid,
        order: orderValue,
      };
      // すでにあれば更新し、なければ追加する
      const newConditions = formik.values.simple.orderby.map((stateCondition) => {
        if (stateCondition.columnUuid === newCondition.columnUuid) {
          return newCondition;
        } else {
          return stateCondition;
        }
      });
      const foundCondition = formik.values.simple.orderby.find(
        (stateCondition) => stateCondition.columnUuid === newCondition.columnUuid
      );
      if (foundCondition === undefined) {
        newConditions.push(newCondition);
      }

      formik.setFieldValue('simple.orderby', newConditions);
    }
  };

  // 条件句の切替
  const handleChangeOrderByValue = (event: SelectChangeEvent) => {
    if (event.target.value === 'asc' || event.target.value === 'desc') {
      setOrderByValue(event.target.value);
    }
  };

  const columnsRow = joinedTables.map((e) => e.columns ?? []).flat(1);

  const onClickConditionSetting = (columnUuid: string) => {
    if (columnUuid) {
      const targetColumn = columnsRow.find((row) => row.columnUuid === columnUuid);
      if (!targetColumn) {
        return;
      }
      const table = joinedTables.find((e) => e.tableUuid === targetColumn.tableUuid);
      setSelectedColumn({
        tableUuid: targetColumn.tableUuid,
        columnUuid: targetColumn.columnUuid,
        name: `${table?.alias ?? `${table?.schemaN}.${table?.tableN}`}.${targetColumn.name}`,
        type: targetColumn.dataType.type,
      });
    } else {
      setSelectedColumn({ tableUuid: '', columnUuid: '', name: '', type: '' });
    }

    setConditionSettingDialogOpen(true);
  };

  const onClickShowDropDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const notSelectedWithSearchColumn = columnsRow
    .filter((i) => !formik.values.simple.orderby.find((j) => j.columnUuid === i.columnUuid))
    .filter((i) => {
      if (!search) return true;
      const table = joinedTables.find((e) => e.tableUuid === i.tableUuid);
      return `${table?.alias ?? `${table?.schemaN}.${table?.tableN}`}.${i.name}`.includes(search);
    });

  const isOpenPopover = Boolean(anchorEl);
  const popoverId = isOpenPopover ? 'order-by-condition-popover' : undefined;

  useEffect(() => {
    if (anchorEl && columnsRow.length) {
      handleUpdatePopoverPosition();
    }
  }, [anchorEl, formik.values.simple.orderby, columnsRow]);

  const renderColumnSearch = () => {
    return (
      <Grid
        container
        sx={{
          gap: 2,
          mb: 1,
          paddingTop: 2,
          position: 'sticky',
          top: 0,
          backgroundColor: colors.common.white,
          zIndex: 1,
        }}
      >
        <Grid item xs={12}>
          <TextField
            value={search}
            onChange={onChangeSearch}
            fullWidth
            placeholder="項目を入力してください"
          />
        </Grid>
      </Grid>
    );
  };

  const renderColumnArea = () => {
    if (!formik.values?.tableN || !formik.values?.datasource)
      return (
        <>
          {renderColumnSearch()}
          <Grid container item xs={12}>
            <Typography style={{ color: 'red' }}>
              データソースおよびテーブルを選択してください。
            </Typography>
          </Grid>
        </>
      );
    if (isColumnLoading)
      return (
        <Box flex={1} padding={2} display="flex" justifyContent="center" alignItems="center">
          <CircularProgress color="inherit" size={20} />
        </Box>
      );
    if (columnsRow.length && notSelectedWithSearchColumn.length) {
      return (
        <>
          {renderColumnSearch()}
          {notSelectedWithSearchColumn.map((row) => {
            const table = joinedTables.find((e) => e.tableUuid === row.tableUuid);
            return (
              <MenuItem
                onClick={() => {
                  onClickConditionSetting(row.columnUuid);
                }}
                key={`order_by_option_${row.columnUuid}`}
                value={row.columnUuid}
              >
                <Box width="100%" flexDirection="row" display="flex">
                  <Box flex={1}>
                    <ListItemText
                      sx={{ wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}
                      secondary={row.dataType.type}
                      primary={`${table?.alias ?? `${table?.schemaN}.${table?.tableN}`}.${row.name}`}
                    />
                  </Box>
                  <Box sx={{ paddingLeft: 1 }}>
                    <ListItemText primary="条件設定" />
                  </Box>
                </Box>
              </MenuItem>
            );
          })}
        </>
      );
    }
    return (
      <>
        {renderColumnSearch()}
        <Grid container>
          <Grid item xs={12}>
            <Typography style={{ color: 'red' }}>有効な項目がありません。</Typography>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <>
      <Grid container marginTop={4} justifyContent="center">
        <Grid item xs={12}>
          <Typography>並び順の設定</Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          {formik.values.simple.orderby.length ? (
            <List sx={{ border: '1px solid #EEEEEE', borderRadius: '5px', padding: 0 }}>
              {formik.values.simple.orderby.map((orderBy, index) => {
                const table = joinedTables.find((e) => e.tableUuid === orderBy.tableUuid);
                const column = table?.columns?.find((e) => e.columnUuid === orderBy.columnUuid);
                const name = `${table?.alias ?? `${table?.schemaN}.${table?.tableN}`}.${column?.name} (${column?.dataType.type})`;
                return (
                  <div key={`order_by_${orderBy.columnUuid}`}>
                    <ListItem>
                      {name.length > MAX_COLUMN_TEXT_LENGTH ? (
                        <Tooltip
                          placement="bottom-start"
                          sx={{ wordBreak: 'break-word' }}
                          title={convertOrderByToString(name, orderBy)}
                        >
                          <ListItemText>
                            <Typography
                              component="p"
                              sx={{ wordBreak: 'break-word', fontWeight: 'bold' }}
                              variant="body1"
                            >
                              {convertOrderByToString(shortenColumnName(name), orderBy)}
                            </Typography>
                          </ListItemText>
                        </Tooltip>
                      ) : (
                        <ListItemText>
                          <Typography
                            component="p"
                            sx={{ wordBreak: 'break-word', fontWeight: 'bold' }}
                            variant="body1"
                          >
                            {convertOrderByToString(shortenColumnName(name), orderBy)}
                          </Typography>
                        </ListItemText>
                      )}
                      <Tooltip title="編集" placement="top">
                        <IconButton
                          onClick={() => {
                            onClickUpdateSettingOrderByCondition(orderBy);
                          }}
                          sx={{ marginRight: 1 }}
                        >
                          <EditOutlined />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="削除" placement="top">
                        <IconButton
                          onClick={() => {
                            onClickDeleteOrderByCondition(orderBy);
                          }}
                        >
                          <Cancel />
                        </IconButton>
                      </Tooltip>
                    </ListItem>
                    {formik.values.simple.orderby.length === index + 1 ? null : <Divider />}
                  </div>
                );
              })}
            </List>
          ) : null}
          <Button variant="text" aria-describedby={popoverId} onClick={onClickShowDropDown}>
            + 追加
          </Button>
          <Popover
            id={popoverId}
            action={popoverAction}
            open={isOpenPopover}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            PaperProps={{
              sx: {
                maxHeight: 300,
                width: 'min(450px, 100%)',
                paddingX: 2,
                paddingBottom: 2,
                position: 'relative',
              },
            }}
          >
            {renderColumnArea()}
          </Popover>
        </Grid>
      </Grid>

      <Dialog
        fullWidth
        maxWidth="md"
        open={conditionSettingDialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Typography variant="h6" marginBottom={2}>
            条件設定
          </Typography>
          <Paper elevation={0} sx={{ backgroundColor: '#FAFAFA' }}>
            <Grid container padding={3} gap={2}>
              <Grid item xs={6}>
                <Typography variant="caption">カラム</Typography>
                <Typography sx={{ wordBreak: 'break-word' }}>{selectedColumn?.name}</Typography>
              </Grid>

              <Grid item xs={5}>
                <Typography variant="caption">データ型</Typography>
                <Typography>{selectedColumn?.type}</Typography>
              </Grid>
            </Grid>
          </Paper>

          <Grid container paddingTop={2}>
            <Grid item xs={12}>
              <Box sx={{}}>
                <FormControl>
                  <Select
                    id="demo-simple-select"
                    value={orderValue}
                    onChange={handleChangeOrderByValue}
                  >
                    <MenuItem value={'asc'}>asc（昇順↑）</MenuItem>
                    <MenuItem value={'desc'}>desc（降順↓）</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions sx={{ margin: 4 }}>
          <Button
            onClick={() => setConditionSettingDialogOpen(false)}
            color="inherit"
            sx={{ marginRight: 2 }}
          >
            キャンセル
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleDialogOk(), setConditionSettingDialogOpen(false);
            }}
            color="primary"
            autoFocus
          >
            追加
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OrderByConditionParts;
