import {
  ContentCopyOutlined,
  DeleteOutline,
  EditOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { VariantType, useSnackbar as useNotistackSnackbar } from 'notistack';
import React, { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import jwtAxios from '../../common/axios';
import { MAX_ARRAY_SIZE } from '../../common/const/maxArraySize';
import { keysToSnake } from '../../common/func/converter';
import {
  convertJoinStatementToString,
  convertOrderByToString,
  convertWhereConditionToString,
  simpleRenderCellDate,
} from '../../common/func/functions';
import { useQueriesSwr } from '../../common/swr/useQueriesSwr';
import { GetQueriesQuery, PostDownloadReq, WhereCondition } from '../../common/types/Responses';
import { useSnackbar } from '../../hooks/SnackBar';
import { useAuth } from '../../hooks/use-auth';
import { useProgress } from '../../hooks/useProgress';
import { generateUuid } from '../../modules/common';
import ConfirmDialogDelete from './ConfirmDialogDelete';
import TablePaginationActions from './TablePaginationActions';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import EditWhereConditionDialog from './EditWhereConditionDialog';

export default function QueriesTable() {
  const auth = useAuth();
  const navigation = useNavigate();
  const { showSnackbar, toggleIsLicValidity } = useSnackbar();
  const { showProgress } = useProgress();
  const { enqueueSnackbar } = useNotistackSnackbar();

  // APIs
  const { queries, isLoading, isError, mutate } = useQueriesSwr();

  const { cache } = useSWRConfig();

  // swr error
  useEffect(() => {
    if (isError)
      showSnackbar(
        `ダウンロード条件一覧取得APIエラー (${isError.response.data['detail']})`,
        'error'
      );
  }, [isError, showSnackbar]);

  const [selectedUuid, setSelectedUuid] = useState('');
  const [deleteQueryName, setDeleteQueryName] = useState<string>('');

  const [dialogOpen, setDialogOpen] = useState(false);
  const [singleDataExtractionDialogOpen, setSingleDataExtractionDialogOpen] = useState(false);

  const handleDialogCancel = () => {
    setSelectedUuid('');
    setDialogOpen(false);
  };
  const handleDialogOk = () => {
    showProgress(true);
    jwtAxios
      .delete(`/api/query/?uuid=${selectedUuid}`)
      .then(() => {
        toggleIsLicValidity(false);
        showProgress(false);
        showSnackbar('クエリを削除しました。', 'success');
        if (queries.length < MAX_ARRAY_SIZE) {
          const updatedQueries = queries.filter((queries) => {
            return queries.uuid !== selectedUuid;
          });
          mutate(updatedQueries, true);
        } else {
          cache.delete(`api/queries/`);
        }

        // 再検証
        void mutate();
      })
      .catch((error) => {
        toggleIsLicValidity(true, error.response.status, error.response.data['licmessage']);
        showProgress(false);
        showSnackbar(`クエリの削除に失敗しました。 (${error.response.data['detail']})`, 'error');
      });
    setDialogOpen(false);
  };

  const onClickDelete = (uuid: string) => {
    setSelectedUuid(uuid);
    setDialogOpen(true);
  };

  const onClickSingleDataExtraction = (uuid: string): void => {
    const query = queries.find((q) => q.uuid === uuid);
    if (!query) {
      return;
    }

    const hasEditableCondition = query.simple?.where.some((w) => w.editable);

    if (!hasEditableCondition) {
      singleDataDownload(uuid);
    } else {
      setSelectedUuid(uuid);
      setSingleDataExtractionDialogOpen(true);
    }
  };

  const handleSingleDataExtractionDialogClose = () => {
    setSelectedUuid('');
    setSingleDataExtractionDialogOpen(false);
  };

  const handleSingleDataExtractionDialogOk = (editedConditions: WhereCondition[]) => {
    if (selectedUuid) {
      showProgress(true);
      singleDataDownload(selectedUuid, editedConditions);
    }
    handleSingleDataExtractionDialogClose();
  };

  const startDownload = () => {
    if (selected.length === 0) {
      return;
    }
    showProgress(true);
    selected.forEach((uuid) => singleDataDownload(uuid));
  };

  const singleDataDownload = (uuid: string, editedConditions?: WhereCondition[]) => {
    const query = queries.find((q) => q.uuid === uuid);
    if (!query) return;

    const req: PostDownloadReq = {
      userName: auth.userId,
      queryId: uuid,
    };

    if (editedConditions) req.simple = { where: editedConditions.filter((c) => c.editable) };

    const variant: VariantType = 'success';
    enqueueSnackbar(`${query.queryName}のデータ抽出を開始しました。`, { variant });

    showProgress(true);
    jwtAxios
      .post('/api/download/', keysToSnake(req))
      .then(() => {
        toggleIsLicValidity(false);
        showProgress(false);
      })
      .catch((error) => {
        toggleIsLicValidity(true, error.response.status, error.response.data['licmessage']);
        showProgress(false);
        showSnackbar(`データ抽出に失敗しました。 (${error.response.data['detail']})`, 'error');
      });
  };

  const [selected, setSelected] = useState<readonly string[]>([]);
  const isSelected = (name: string) => selected.indexOf(name) !== -1;
  const handleCheckBoxClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  function Row(props: { row: GetQueriesQuery }) {
    const { row } = props;
    const auth = useAuth();

    const [open, setOpen] = useState(false);

    const isItemSelected = isSelected(row.uuid);
    const labelId = `enhanced-table-checkbox-${row.uuid}`;

    const datasourceProperties = [
      { データソース名: row.datasourceName },
      { データベース: row.dbN },
      { スキーマ: row.schemaN },
      { テーブル: row.tableN },
    ];

    return (
      <Fragment>
        <TableRow
          sx={{ '& > *': { borderBottom: 'unset' } }}
          hover
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          selected={isItemSelected}
        >
          <TableCell padding="checkbox" sx={{ borderBottom: 'unset' }}>
            <Checkbox
              onClick={(event) => handleCheckBoxClick(event, row.uuid)}
              color="primary"
              checked={isItemSelected}
              inputProps={{
                'aria-labelledby': labelId,
              }}
            />
          </TableCell>
          <TableCell sx={{ borderBottom: 'unset' }}>
            <Tooltip
              placement="bottom-start"
              sx={{ wordBreak: 'break-word' }}
              title={row.queryName}
            >
              <Typography
                sx={{
                  wordBreak: 'break-word',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: '1',
                  display: '-webkit-box',
                }}
              >
                {row.queryName}
              </Typography>
            </Tooltip>
          </TableCell>
          <TableCell sx={{ borderBottom: 'unset' }}>
            <Tooltip
              placement="bottom-start"
              sx={{ wordBreak: 'break-word' }}
              title={row.description}
            >
              <Typography
                sx={{
                  wordBreak: 'break-word',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: '1',
                  display: '-webkit-box',
                }}
              >
                {row.description}
              </Typography>
            </Tooltip>
          </TableCell>
          <TableCell
            align="right"
            sx={(theme) => ({
              borderBottom: 'unset',
              minWidth: 250,
              [theme.breakpoints.down('md')]: {
                minWidth: 'unset',
              },
            })}
          >
            {/* 単一データ抽出実行 */}
            <Tooltip title={'単一データ抽出実行'} placement="top">
              <span>
                <IconButton
                  aria-label="single-download"
                  size="small"
                  sx={{ marginRight: 2 }}
                  onClick={() => onClickSingleDataExtraction(row.uuid)}
                  disabled={auth.license?.isExpired}
                >
                  <SlideshowIcon />
                </IconButton>
              </span>
            </Tooltip>
            {/* 複製 */}
            <Tooltip title="複製" placement="top">
              <IconButton
                aria-label="expand row"
                size="small"
                sx={{ marginRight: 2 }}
                onClick={() => {
                  if (row.query) {
                    navigation(`/sql_setting/new`, {
                      state: { query: row },
                    });
                  } else {
                    navigation(`/simple_setting/new`, {
                      state: { query: row },
                    });
                  }
                }}
                disabled={
                  auth.license?.isExpired || auth.frontendUserRole === 'read' || !row.editable
                }
              >
                <ContentCopyOutlined />
              </IconButton>
            </Tooltip>
            {/* 編集 */}
            <Tooltip title={row.editable ? '編集' : '編集権限がありません'} placement="top">
              <span>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  sx={{ marginRight: 2 }}
                  onClick={() => {
                    if (row.query) {
                      navigation(`/sql_setting/new/${row.uuid}`);
                    } else {
                      navigation(`/simple_setting/new/${row.uuid}`);
                    }
                  }}
                  disabled={auth.license?.isExpired ? true : !row.editable}
                >
                  <EditOutlined />
                </IconButton>
              </span>
            </Tooltip>
            {/* 削除 */}
            <Tooltip title={row.deletable ? '削除' : '削除権限がありません'} placement="top">
              <span>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  sx={{ marginRight: 2 }}
                  onClick={() => {
                    setDeleteQueryName(row.queryName);
                    onClickDelete(row.uuid);
                  }}
                  disabled={auth.license?.isExpired ? true : !row.deletable}
                >
                  <DeleteOutline />
                </IconButton>
              </span>
            </Tooltip>
            {/* 詳細を開く */}
            <Tooltip title="詳細" placement="top">
              <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Grid container justifyContent="center">
                  <Grid item xs={12}>
                    <Paper
                      sx={{
                        minHeight: '100px',
                        backgroundColor: '#FAFAFA',
                        padding: 2,
                        margin: 1,
                      }}
                      elevation={0}
                    >
                      <Box sx={{ display: 'flex' }}>
                        {datasourceProperties.map((obj) => {
                          const key = Object.entries(obj).flat()[0];
                          const value = Object.entries(obj).flat()[1];
                          if (!value) {
                            return;
                          }
                          return (
                            <Box key={generateUuid()} sx={{ flex: '1' }}>
                              <Typography variant="caption">{key}</Typography>
                              <div>{value}</div>
                            </Box>
                          );
                        })}
                        <Box sx={{ flex: '1' }}>
                          <Typography variant="caption">タイプ</Typography>
                          <div>
                            {row.query ? '高度な条件設定（SQL）' : 'シンプルな条件設定'}
                            {row.csvBomOption && '[E]'}
                          </div>
                        </Box>
                      </Box>
                    </Paper>
                  </Grid>
                  {(row.simple?.join ?? []).length !== 0 && (
                    <Grid item xs={12}>
                      <Paper
                        sx={{
                          minHeight: '130px',
                          backgroundColor: '#FAFAFA',
                          padding: 2,
                          margin: 1,
                        }}
                        elevation={0}
                      >
                        <Typography variant="caption">結合テーブル</Typography>
                        {row.simple?.join.map((join, index) => {
                          return (
                            <Typography
                              key={`join_${join.schemaN}_${join.table}_${join.tableAlias}_${index}`}
                              component="p"
                              sx={{ wordBreak: 'break-word' }}
                              variant="body2"
                            >
                              {convertJoinStatementToString(join)}
                            </Typography>
                          );
                        })}
                      </Paper>
                    </Grid>
                  )}
                </Grid>
                <Grid container justifyContent="center">
                  {row.query ? (
                    <Grid item xs={8}>
                      <Paper
                        sx={{
                          minHeight: '260px',
                          backgroundColor: '#FAFAFA',
                          padding: 2,
                          margin: 1,
                        }}
                        elevation={0}
                      >
                        <Typography variant="caption">SQL</Typography>
                        <TextField
                          placeholder="SQL文が表示されます"
                          value={row.query}
                          multiline
                          rows={8}
                          fullWidth
                          variant="standard"
                          InputProps={{
                            readOnly: true,
                            disableUnderline: true,
                            style: {
                              fontSize: '0.875rem',
                            },
                          }}
                          sx={{
                            border: 'none',
                          }}
                        />
                      </Paper>
                    </Grid>
                  ) : (
                    <>
                      <Grid item xs={4}>
                        <Paper
                          sx={{
                            minHeight: '260px',
                            backgroundColor: '#FAFAFA',
                            padding: 2,
                            margin: 1,
                          }}
                          elevation={0}
                        >
                          <Typography variant="caption">抽出項目</Typography>
                          {row.simple?.select.length === 0 ? (
                            <div>全項目</div>
                          ) : (
                            (row.simple?.select ?? []).map((field, index) => (
                              <Typography
                                key={`select_${field.schemaN}_${field.table}_${field.tableAlias}_${field.column}_${index}`}
                                component="p"
                                sx={{ wordBreak: 'break-word' }}
                                variant="body2"
                              >
                                {`${field.tableAlias ?? `${field.schemaN}.${field.table}`}.${
                                  field.column
                                }`}
                              </Typography>
                            ))
                          )}
                        </Paper>
                      </Grid>

                      <Grid item xs={4}>
                        <Paper
                          sx={{
                            minHeight: '260px',
                            backgroundColor: '#FAFAFA',
                            padding: 2,
                            margin: 1,
                          }}
                          elevation={0}
                        >
                          <Typography variant="caption">抽出条件</Typography>
                          <div>
                            {(row.simple?.where ?? []).map((condition, index) => (
                              <Typography
                                key={`where_${condition.schemaN}_${condition.table}_${condition.tableAlias}_${condition.column}_${index}`}
                                component="p"
                                sx={{ wordBreak: 'break-word' }}
                                variant="body2"
                              >
                                {convertWhereConditionToString(
                                  `${
                                    condition.tableAlias ??
                                    `${condition.schemaN}.${condition.table}`
                                  }.${condition.column}`,
                                  condition
                                )}
                              </Typography>
                            ))}

                            {(row.simple?.orderby ?? []).map((orderBy, index) => (
                              <Typography
                                key={`orderBy_${orderBy.schemaN}_${orderBy.table}_${orderBy.tableAlias}_${orderBy.column}_${index}`}
                                component="p"
                                sx={{ wordBreak: 'break-word' }}
                                variant="body2"
                              >
                                {convertOrderByToString(
                                  `${orderBy.tableAlias ?? `${orderBy.schemaN}.${orderBy.table}`}.${
                                    orderBy.column
                                  }`,
                                  orderBy
                                )}
                              </Typography>
                            ))}
                          </div>
                        </Paper>
                      </Grid>
                    </>
                  )}

                  <Grid item xs={4}>
                    <Grid container justifyContent="center">
                      <Grid item xs={12}>
                        <Paper
                          sx={{
                            minHeight: '122px',
                            backgroundColor: '#FAFAFA',
                            padding: 2,
                            margin: 1,
                          }}
                          elevation={0}
                        >
                          <Typography variant="caption">最終アップデート日時</Typography>
                          {simpleRenderCellDate(row.updatedAt)}
                        </Paper>
                      </Grid>

                      <Grid item xs={12}>
                        <Paper
                          sx={{
                            minHeight: '122px',
                            backgroundColor: '#FAFAFA',
                            padding: 2,
                            margin: 1,
                          }}
                          elevation={0}
                        >
                          <Typography variant="caption">最終ダウンロード日時</Typography>
                          {simpleRenderCellDate(row.lastDownloadAt)}
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  }

  // ページング処理
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - queries.length) : 0;
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <div style={{ width: '100%' }}>
        <Grid container justifyContent="flex-end">
          <Box
            component="span"
            display="flex"
            justifyContent="space-evenly"
            alignItems="center"
            sx={{ position: 'relative', bottom: '50px' }}
          >
            {auth.frontendUserRole === 'read' ? null : (
              <Button
                type="submit"
                variant="outlined"
                sx={{ marginRight: 2 }}
                onClick={() => {
                  navigation(`/download`);
                }}
                disabled={auth.license?.isExpired}
              >
                新規条件作成
              </Button>
            )}

            <Button
              type="submit"
              variant="contained"
              disabled={auth.license?.isExpired ? true : selected.length ? false : true}
              onClick={() => {
                startDownload();
              }}
            >
              データ抽出実行
            </Button>
          </Box>
        </Grid>
      </div>

      <div style={{ display: 'flex', height: '100%' }}>
        <TableContainer component={Paper} sx={{ marginBottom: 4 }}>
          <Table aria-label="collapsible table">
            <TableHead
              sx={{
                backgroundColor: '#EEEEEE',
              }}
            >
              <TableRow>
                <TableCell></TableCell>
                <TableCell sx={{ width: '30%' }}>タイトル</TableCell>
                <TableCell sx={{ width: '45%' }}>説明</TableCell>
                <TableCell align="right" />
              </TableRow>
            </TableHead>

            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {queries.length > 0 ? (
                  (rowsPerPage > 0
                    ? queries.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : queries
                  ).map((row) => <Row key={generateUuid()} row={row} />)
                ) : (
                  <TableRow>
                    <TableCell colSpan={12} style={{ textAlign: 'center' }}>
                      データ抽出条件がありません。
                    </TableCell>
                  </TableRow>
                )}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            )}

            <TableFooter>
              <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50, 100, 1000]}
                  count={queries.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  labelRowsPerPage={'表示件数'}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>

      <ConfirmDialogDelete
        isOpen={dialogOpen}
        tile={deleteQueryName}
        cancelFunc={() => {
          handleDialogCancel();
        }}
        okFunc={handleDialogOk}
      />

      <EditWhereConditionDialog
        isOpen={singleDataExtractionDialogOpen}
        conditions={queries.find((q) => q.uuid === selectedUuid)?.simple?.where ?? []}
        cancelFunc={handleSingleDataExtractionDialogClose}
        okFunc={handleSingleDataExtractionDialogOk}
      />
    </>
  );
}
