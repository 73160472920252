import { ConditionOption } from '../types/whereCondition';

export const TIME_UNIT_OPTIONS = [
  {
    value: 'year',
    label: '年',
  },
  {
    value: 'month',
    label: 'ヶ月',
  },
  {
    value: 'day',
    label: '日',
  },
  {
    value: 'hour',
    label: '時間',
  },
];

export const NUMBER_OPERATORS: ConditionOption[] = [
  {
    value: '=',
    label: 'と等しい',
  },
  {
    value: '<=',
    label: '以下',
  },
  {
    value: '>=',
    label: '以上',
  },
  {
    value: '!=',
    label: 'と等しくない',
  },
];

export const MATCH_OPERATORS: ConditionOption[] = [
  {
    value: '=',
    label: 'と一致する',
  },
  {
    value: '!=',
    label: 'と一致しない',
  },
];

export const LIKE_OPERATORS: ConditionOption[] = [
  {
    value: 'LIKE',
    label: 'を含む',
  },
  {
    value: 'PREFIX_LIKE',
    label: 'ではじまる',
  },
  {
    value: 'SUFFIX_LIKE',
    label: 'で終わる',
  },
];
