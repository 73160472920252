import { Join, OrderByCondition, SimpleQuery, WhereCondition } from '../types/Responses';
import { GridRenderCellParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { MAX_COLUMN_TEXT_LENGTH } from '../const/maxTextLength';
import { Column, JoinedTable, OrderBy, SimpleQuery as TypeSimpleQuery, Where } from '../types';
import { JOIN_TYPES } from '../const/joinTable';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Tokyo');

export const utcToJstFormat = (utcStr?: string) => {
  return utcStr ? dayjs.utc(utcStr).local().format('YYYY-MM-DD HH:mm:ss') : '';
};

export const renderCellDate = (
  params: GridRenderCellParams<{ [key: string]: any }>,
  property: string
) => {
  if (params.row[property] === undefined || params.row[property] === null) {
    return undefined;
  } else {
    const dateStr = utcToJstFormat(params.row[property]);
    const dateStrList = dateStr.split(' ');
    const yymmdd = dateStrList[0];
    const hhmmss = dateStrList[1];
    return (
      <div>
        <div>{yymmdd}</div>
        <div>{hhmmss}</div>
      </div>
    );
  }
};

export const simpleRenderCellDate = (params: string) => {
  if (!params) {
    return undefined;
  } else {
    const dateStr = utcToJstFormat(params);
    const dateStrList = dateStr.split(' ');
    const yymmdd = dateStrList[0];
    const hhmmss = dateStrList[1];
    return (
      <div>
        <div>{yymmdd}</div>
        <div>{hhmmss}</div>
      </div>
    );
  }
};

export const getSecond2String = (duration: number) => {
  const hour = Math.floor(duration / 3600000);
  const minute = Math.floor((duration - 3600000 * hour) / 60000);

  const hh = ('00' + hour).slice(-2);
  const mm = ('00' + minute).slice(-2);
  const ms = ('00000' + (duration % 60000)).slice(-5);

  const time = `${hh}:${mm}:${ms.slice(0, 2)}.${ms.slice(2, 5)}`;

  return time;
};

export const convertJoinStatementToString = (join: Join) => {
  let joinStatement = '';
  if (join.type) {
    joinStatement += Object.values(JOIN_TYPES).find((e) => e.CODE === join.type)?.KEYWORD || '';
  }
  if (join.schemaN && join.table) {
    joinStatement += ` ${join.schemaN}.${join.table}`;
    if (join.tableAlias) {
      joinStatement += ` AS ${join.tableAlias}`;
    }
  }
  if (join.conditions.length > 0) {
    joinStatement += ` ON ${join.conditions
      .map((e) => {
        const localKey = `${
          e.localKey?.tableAlias
            ? e.localKey?.tableAlias
            : `${e.localKey?.schemaN}.${e.localKey?.table}`
        }.${e.localKey?.column}`;
        const foreignKey = `${
          e.foreignKey?.tableAlias
            ? e.foreignKey?.tableAlias
            : `${e.foreignKey?.schemaN}.${e.foreignKey?.table}`
        }.${e.foreignKey?.column}`;
        return `${localKey} ${e.operator} ${foreignKey}`;
      })
      .join(' AND ')}`;
  }

  return joinStatement;
};

export const convertWhereConditionToString = (
  column: string,
  whereCondition: Where | WhereCondition
) => {
  let conditionString = '';
  if (whereCondition.type === 'time') {
    if ('values' in whereCondition && whereCondition.values !== null) {
      conditionString = `${column} が ${whereCondition.values.join(' と ')} の間`;
    } else if ('unit' in whereCondition) {
      switch (whereCondition.unit) {
        case 'hour':
          conditionString = `${column} が ${whereCondition.value} 時間以内`;
          break;
        case 'day':
          conditionString = `${column} が ${whereCondition.value} 日以内`;
          break;
        case 'month':
          conditionString = `${column} が ${whereCondition.value} ヶ月以内`;
          break;
        case 'year':
          conditionString = `${column} が ${whereCondition.value} 年以内`;
          break;
        default:
          return '';
      }
    }
  } else if (whereCondition.type === 'text') {
    switch (whereCondition.operator) {
      case '=':
        conditionString = `${column} が ${whereCondition.value} と一致する`;
        break;
      case '!=':
        conditionString = `${column} が ${whereCondition.value} と一致しない`;
        break;
      case 'LIKE':
        conditionString = `${column} が ${whereCondition.value} を含む`;
        break;
      case 'PREFIX_LIKE':
        conditionString = `${column} が ${whereCondition.value} ではじまる`;
        break;
      case 'SUFFIX_LIKE':
        conditionString = `${column} が ${whereCondition.value} で終わる`;
        break;
      default:
        return '';
    }
  } else if (whereCondition.type === 'num') {
    switch (whereCondition.operator) {
      case '=':
        conditionString = `${column} が ${whereCondition.value} と等しい`;
        break;
      case '!=':
        conditionString = `${column} が ${whereCondition.value} と等しくない`;
        break;
      case '<=':
        conditionString = `${column} が ${whereCondition.value} 以下`;
        break;
      case '>=':
        conditionString = `${column} が ${whereCondition.value} 以上`;
        break;
      default:
        return '';
    }
  } else if (whereCondition.type === 'bool') {
    if (whereCondition.value === 'true') {
      conditionString = `${column} が真`;
    } else if (whereCondition.value === 'false') {
      conditionString = `${column} が偽`;
    }
  }

  if (!conditionString) return '';

  if (whereCondition.editable) {
    conditionString += ` (変更可能)`;
  }

  return conditionString;
};

export const convertOrderByToString = (
  column: string,
  orderByCondition: OrderBy | OrderByCondition
) => {
  if (orderByCondition.order === 'asc') {
    return [`${column}：`, '昇順↑'].join(' ');
  } else {
    return [`${column}：`, '降順↓'].join(' ');
  }
};

export const shortenColumnName = (column: string) => {
  return column.length > MAX_COLUMN_TEXT_LENGTH
    ? `${column.slice(0, MAX_COLUMN_TEXT_LENGTH)}...`
    : column;
};

export const formatSimpleQueryData = (
  simpleData: TypeSimpleQuery,
  joinedTables: JoinedTable[]
): SimpleQuery => {
  return {
    select: mapProperties(simpleData.select, joinedTables, (item, table, column) => ({
      schemaN: table.schemaN,
      table: table.tableN,
      tableAlias: table.alias,
      column: column.name,
      alias: item.alias?.trim(),
    })),
    join: simpleData.join.map((e) => {
      const table = joinedTables.find((jt) => jt.tableUuid === e.tableUuid);
      return {
        type: e.type,
        schemaN: e.schemaN,
        table: e.tableN,
        tableAlias: table?.alias,
        conditions: e.conditions.map((e1) => {
          const localKeyTable = joinedTables.find((jt) => jt.tableUuid === e1.localKey?.tableUuid);
          return {
            localKey:
              localKeyTable === undefined || e1.localKey === undefined
                ? undefined
                : {
                    schemaN: localKeyTable.schemaN,
                    table: localKeyTable.tableN,
                    tableAlias: localKeyTable.alias,
                    column: e1.localKey.name,
                  },
            operator: e1.operator,
            foreignKey:
              table === undefined || e1.foreignKey === undefined
                ? undefined
                : {
                    schemaN: table.schemaN,
                    table: table.tableN,
                    tableAlias: table.alias,
                    column: e1.foreignKey.name,
                  },
          };
        }),
      };
    }),
    where: mapProperties(simpleData.where, joinedTables, (item, table, column) => {
      const where: WhereCondition = {
        ...item,
        schemaN: table.schemaN,
        table: table.tableN,
        tableAlias: table.alias,
        column: column.name,
        alias: simpleData.select.find((e) => e.columnUuid === item.columnUuid)?.alias?.trim(),
      };
      if ('tableUuid' in where) delete where.tableUuid;
      if ('columnUuid' in where) delete where.columnUuid;
      return where;
    }),
    orderby: mapProperties(simpleData.orderby, joinedTables, (item, table, column) => ({
      schemaN: table.schemaN,
      table: table.tableN,
      tableAlias: table.alias,
      column: column.name,
      alias: simpleData.select.find((e) => e.columnUuid === item.columnUuid)?.alias?.trim(),
      order: item.order,
    })),
  };
};

const mapProperties = <
  T extends {
    tableUuid: string;
    columnUuid: string;
  },
  U,
>(
  data: T[],
  joinedTables: JoinedTable[],
  transform: (item: T, table: JoinedTable, column: Column) => U
): U[] => {
  return data.reduce((result: U[], item) => {
    const table = joinedTables.find((jt) => jt.tableUuid === item.tableUuid);
    const column = table?.columns?.find((col) => col.columnUuid === item.columnUuid);
    if (table === undefined || column === undefined) return result;
    result.push(transform(item, table, column));
    return result;
  }, []);
};

export const generateConditionString = (whereCondition: WhereCondition): string => {
  if (whereCondition.type === 'time') {
    if ('values' in whereCondition && whereCondition.values !== null) {
      return `${whereCondition.values.join(' と ')} の間`;
    } else if ('unit' in whereCondition) {
      switch (whereCondition.unit) {
        case 'hour':
          return `${whereCondition.value} 時間以内`;
        case 'day':
          return `${whereCondition.value} 日以内`;
        case 'month':
          return `${whereCondition.value} ヶ月以内`;
        case 'year':
          return `${whereCondition.value} 年以内`;
        default:
          return '';
      }
    }
  } else if (whereCondition.type === 'text') {
    switch (whereCondition.operator) {
      case '=':
        return `${whereCondition.value} と一致する`;
      case '!=':
        return `${whereCondition.value} と一致しない`;
      case 'LIKE':
        return `${whereCondition.value} を含む`;
      case 'PREFIX_LIKE':
        return `${whereCondition.value} ではじまる`;
      case 'SUFFIX_LIKE':
        return `${whereCondition.value} で終わる`;
      default:
        return '';
    }
  } else if (whereCondition.type === 'num') {
    switch (whereCondition.operator) {
      case '=':
        return `${whereCondition.value} と等しい`;
      case '!=':
        return `${whereCondition.value} と等しくない`;
      case '<=':
        return `${whereCondition.value} 以下`;
      case '>=':
        return `${whereCondition.value} 以上`;
      default:
        return '';
    }
  } else if (whereCondition.type === 'bool') {
    if (whereCondition.value === 'true') {
      return `が真`;
    } else if (whereCondition.value === 'false') {
      return `が偽`;
    }
  }

  return '';
};
