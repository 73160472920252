import {
  BooleanComparisonCondition,
  BooleanComparisonValue,
  NumberComparisonCondition,
  TextComparisonCondition,
  TimeBetweenCondition,
  TimeComparisonCondition,
  WhereCondition,
} from '../../common/types/Responses';
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { DesktopDatePickerProps } from '@mui/lab';
import { generateConditionString } from '../../common/func/functions';
import {
  LIKE_OPERATORS,
  MATCH_OPERATORS,
  NUMBER_OPERATORS,
  TIME_UNIT_OPTIONS,
} from '../../common/const/whereCondition';

dayjs.extend(utc);

type Props = {
  isOpen: boolean;
  conditions: WhereCondition[];
  cancelFunc: () => void;
  okFunc: (editedConditions: WhereCondition[]) => void;
};

export default function EditWhereConditionDialog({
  isOpen,
  conditions,
  cancelFunc,
  okFunc,
}: Props): React.JSX.Element {
  const [editedConditions, setEditedConditions] = useState<WhereCondition[]>([]);
  useEffect(() => {
    if (isOpen) {
      setEditedConditions([...conditions]);
    } else {
      setEditedConditions([]);
    }
  }, [isOpen]);

  const updateCondition = (index: number, condition: WhereCondition): void => {
    setEditedConditions((currentConditions) => {
      const newConditions = [...currentConditions];
      newConditions[index] = condition;
      return newConditions;
    });
  };

  const getEditableInput = (condition: WhereCondition, index: number): React.JSX.Element => {
    if (!condition.editable) {
      return <Typography fontWeight={'bold'}>{generateConditionString(condition)}</Typography>;
    }

    if (condition.type === 'time') {
      if ((condition as TimeComparisonCondition)?.value) {
        return (
          <TimeCondition
            index={index}
            condition={condition as TimeComparisonCondition}
            updateCondition={updateCondition}
          />
        );
      }
      if ((condition as TimeBetweenCondition)?.values) {
        return (
          <TimeRangeCondition
            index={index}
            condition={condition as TimeBetweenCondition}
            updateCondition={updateCondition}
          />
        );
      }
    } else if (condition.type === 'text') {
      return (
        <TextCondition index={index} condition={condition} updateCondition={updateCondition} />
      );
    } else if (condition.type === 'num') {
      return (
        <NumberCondition index={index} condition={condition} updateCondition={updateCondition} />
      );
    } else if (condition.type === 'bool') {
      return (
        <BooleanCondition index={index} condition={condition} updateCondition={updateCondition} />
      );
    }

    return <></>;
  };

  const conditionRow = (condition: WhereCondition, index: number): React.JSX.Element => {
    return (
      <React.Fragment key={index}>
        <Grid style={{ wordBreak: 'break-all' }}>
          {`${condition.tableAlias ?? `${condition.schemaN}.${condition.table}`}.${
            condition.column
          }`}
        </Grid>
        <Grid display={'flex'} flexWrap={'wrap'} alignItems={'center'} fontSize={'1.1em'}>
          {getEditableInput(condition, index)}
        </Grid>
      </React.Fragment>
    );
  };

  return (
    <Dialog fullWidth maxWidth="md" open={isOpen}>
      <DialogContent>
        <Typography variant="h6" marginBottom={2}>
          実行時条件設定
        </Typography>
        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Box
            display="grid"
            gridTemplateColumns={{ xs: '1fr', sm: 'fit-content(40%) 1fr' }}
            alignItems={'center'}
            padding={1}
            columnGap={2}
            rowGap={3}
          >
            {conditions.map((condition, index) => conditionRow(condition, index))}
          </Box>
        </Paper>
      </DialogContent>

      <DialogActions sx={{ margin: 4 }}>
        <Button onClick={cancelFunc} color="inherit" sx={{ marginRight: 2 }}>
          キャンセル
        </Button>
        <Button
          variant="contained"
          onClick={() => okFunc(editedConditions)}
          color="primary"
          autoFocus
          disabled={editedConditions.some(
            (condition) =>
              (condition?.type === 'text' && !condition?.value) ||
              (condition?.type === 'time' &&
                (condition as TimeBetweenCondition)?.values?.includes(''))
          )}
        >
          実行
        </Button>
      </DialogActions>
    </Dialog>
  );
}

type TimeProps = {
  index: number;
  condition: TimeComparisonCondition;
  updateCondition: (index: number, condition: TimeComparisonCondition) => void;
};
function TimeCondition({ index, condition, updateCondition }: TimeProps): React.JSX.Element {
  const [timeValue, setTimeValue] = useState<number>(condition.value);
  const unit = TIME_UNIT_OPTIONS.find((uo) => uo.value === condition.unit)?.label ?? '';

  useEffect(() => {
    updateCondition(index, { ...condition, value: timeValue });
  }, [timeValue]);

  return (
    <>
      <TextField
        type="number"
        size="small"
        value={timeValue}
        onChange={(e) => setTimeValue(Number(e.target.value))}
      />
      <Typography component="span" margin={1}>
        {unit}
      </Typography>
    </>
  );
}

type TimeBetweenProps = {
  index: number;
  condition: TimeBetweenCondition;
  updateCondition: (index: number, condition: TimeBetweenCondition) => void;
};
const TimeRangeCondition = ({
  index,
  condition,
  updateCondition,
}: TimeBetweenProps): React.JSX.Element => {
  const [fromDate, setFromDate] = useState<Dayjs | null>(
    condition.values?.[0] ? dayjs(condition.values[0]) : dayjs(new Date().toString())
  );
  const handleFromDateChange: DesktopDatePickerProps<Dayjs>['onChange'] = (
    newValue: Dayjs | null
  ) => setFromDate(newValue);
  const [toDate, setToDate] = useState<Dayjs | null>(
    condition.values?.[1] ? dayjs(condition.values[1]) : dayjs(new Date().toString())
  );
  const handleToDateChange: DesktopDatePickerProps<Dayjs>['onChange'] = (newValue: Dayjs | null) =>
    setToDate(newValue);
  useEffect(
    () =>
      updateCondition(index, {
        ...condition,
        values: [
          fromDate?.isValid() ? fromDate.toISOString() : '',
          toDate?.isValid() ? toDate.toISOString() : '',
        ],
      }),
    [fromDate, toDate]
  );

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          format="YYYY[年]MM[月]DD[日]"
          value={fromDate}
          onChange={handleFromDateChange}
          slotProps={{ textField: { size: 'small', sx: { width: '200px' } } }}
        />
        <Typography component="span" margin={1}>
          と
        </Typography>
        <DesktopDatePicker
          format="YYYY[年]MM[月]DD[日]"
          value={toDate}
          onChange={handleToDateChange}
          slotProps={{ textField: { size: 'small', sx: { width: '200px' } } }}
        />
        <Typography component="span" margin={1}>
          の間
        </Typography>
      </LocalizationProvider>
    </>
  );
};

type NumberProps = {
  index: number;
  condition: NumberComparisonCondition;
  updateCondition: (index: number, condition: NumberComparisonCondition) => void;
};
const NumberCondition = ({ index, condition, updateCondition }: NumberProps): React.JSX.Element => {
  const [numValue, setNumValue] = useState<string>(condition.value);
  const operator = NUMBER_OPERATORS.find((no) => no.value === condition.operator)?.label ?? '';

  useEffect(
    () =>
      updateCondition(index, {
        ...condition,
        value: numValue,
      }),
    [numValue]
  );

  return (
    <>
      <TextField
        type="number"
        size="small"
        value={numValue}
        onChange={(e) => setNumValue(e.target.value)}
      />
      <Typography component="span" margin={1}>
        {operator}
      </Typography>
    </>
  );
};

type TextProps = {
  index: number;
  condition: TextComparisonCondition;
  updateCondition: (index: number, condition: TextComparisonCondition) => void;
};
const TextCondition = ({ index, condition, updateCondition }: TextProps): React.JSX.Element => {
  const [textValue, setTextValue] = useState<string>(condition.value);

  useEffect(
    () =>
      updateCondition(index, {
        ...condition,
        value: textValue,
      }),
    [textValue]
  );

  let operator: string = '';
  if (condition.operator === '=' || condition.operator === '!=') {
    operator = MATCH_OPERATORS.find((mo) => mo.value === condition.operator)?.label ?? '';
  }
  if (['LIKE', 'PREFIX_LIKE', 'SUFFIX_LIKE'].includes(condition.operator)) {
    operator = LIKE_OPERATORS.find((lo) => lo.value === condition.operator)?.label ?? '';
  }

  return (
    <>
      <TextField
        type="text"
        size="small"
        value={textValue}
        onChange={(e) => setTextValue(e.target.value)}
      />
      <Typography component="span" margin={1}>
        {operator}
      </Typography>
    </>
  );
};

type BooleanProps = {
  index: number;
  condition: BooleanComparisonCondition;
  updateCondition: (index: number, condition: BooleanComparisonCondition) => void;
};
const BooleanCondition = ({
  index,
  condition,
  updateCondition,
}: BooleanProps): React.JSX.Element => {
  const [boolValue, setBoolValue] = useState<BooleanComparisonValue>(condition.value);

  useEffect(
    () =>
      updateCondition(index, {
        ...condition,
        value: boolValue,
      }),
    [boolValue]
  );

  return (
    <>
      <Select
        value={boolValue}
        size="small"
        onChange={(e) => setBoolValue(e.target.value as BooleanComparisonValue)}
      >
        <MenuItem value={'false'}>偽</MenuItem>
        <MenuItem value={'true'}>真</MenuItem>
      </Select>
    </>
  );
};
