import {
  BooleanComparisonValue,
  EnclosedText,
  GetColumn,
  NumberComparisonOperator,
  Order,
  TextComparisonOperator,
  TimeComparisonUnit,
} from './types/Responses';

export const UserRole = {
  Superadmin: 'superadmin',
  Admin: 'admin',
  NotAdmin: 'notAdmin',
} as const;
// eslint-disable-next-line prettier/prettier
export type UserRole = (typeof UserRole)[keyof typeof UserRole];
export const AllRoleType = Object.values(UserRole);

export type UserType = {
  name: string;
  role: UserRole;
};

// フロントエンドで取り扱う際のユーザー権限
export const FrontendUserRole = {
  // システム管理者
  SuperAdmin: 'superAdmin',
  // 管理者ユーザー
  Admin: 'admin',
  // 編集権限ユーザー
  Edit: 'edit',
  // 閲覧権限ユーザー
  Read: 'read',
  // グループ未所属ユーザー
  NotAffiliation: 'notAffiliation',
  // ロール取得中
  Pending: 'pending',
} as const;
// eslint-disable-next-line prettier/prettier
export type FrontendUserRole = (typeof FrontendUserRole)[keyof typeof FrontendUserRole];

export type User = {
  firstId: string;
  userName: string;
  adminRole: UserRole;
  email: string;
  updatedAt: string;
  group: MinimalGroup[];
  ceratedAt?: string;
  mfaStatus?: string;
};

export type Group = {
  firstId: string;
  groupName: string;
  tables: GroupsTable[];
  schemas: SchemasTable[];
  users: GroupsUser[];
  createdAt: string;
  updatedAt: string;
};

export type GroupsTable = {
  connectionId: string;
  dbN: string;
  schemaN: string;
  tableN: string;
};

export type SchemasTable = {
  connectionId: string;
  dbN: string;
  schemaN: string;
};

export type GroupsUser = {
  firstId: string;
  role: string;
};

export type Connections = {
  connectionId: string;
  name: string;
  type: string;
  host: string;
  port: number;
  user: string;
  db: string;
};

export type MinimalGroup = {
  groupId: string;
  role: string;
};

export type Tables = Table[];
export type Table = {
  schemaN: string;
  tables: string[];
};

export type QueryDelete = {
  deleteNum: number;
};

export type DatasourceType = 'Redshift' | 'Snowflake';

export type License = {
  licenseKey: string | null;
  expireDate: string;
  userNum: number;
  datasourceNum: number;
  datasourceType: DatasourceType[];
  isValid: boolean;
  checkedAt: string;
  createdAt: string;
  updatedAt: string;
  isApproaching?: boolean;
  isExpired?: boolean;
};

export type SqlInfo = {
  select?: SqlKeyValue;
  from?: SqlKeyValue;
  join?: Array<SqlKeyValue>;
  where?: SqlKeyValue;
  orderBy?: SqlKeyValue;
};

export type SqlKeyValue = {
  key: string;
  value: string;
};

export type SimpleQueryForm = {
  uuid?: string;
  datasource: string;
  queryName: string;
  description: string;
  schemaN: string;
  tableN: string;
  simple: SimpleQuery;
  enclosedText?: EnclosedText;
  csvBomOption: boolean;
};

export type TableInfo = {
  schemaN: string;
  tableN: string;
  columns?: GetColumn[];
};

export type JoinedTable = {
  schemaN: string;
  tableUuid: string;
  tableN: string;
  columns?: Column[];
  alias?: string;
};

export type SimpleQuery = {
  join: Join[];
  select: Select[];
  where: Where[];
  orderby: OrderBy[];
};

export type Join = {
  schemaN: string;
  tableUuid: string;
  tableN: string;
  type: string;
  conditions: JoinCondition[];
};

export type JoinCondition = {
  localKey?: Column;
  foreignKey?: Column;
  operator: string;
};

export type Column = {
  columnUuid: string;
  tableUuid: string;
  name: string;
  dataType: {
    type: string;
    precision: number;
    scale: number;
  };
};

export type Select = {
  tableUuid: string;
  columnUuid: string;
  alias?: string;
};

export type Where =
  | TimeComparisonCondition
  | TimeBetweenCondition
  | TextComparisonCondition
  | NumberComparisonCondition
  | BooleanComparisonCondition;

export type ConditionType = {
  time: 'time';
  text: 'text';
  num: 'num';
  bool: 'bool';
};

export type TimeComparisonCondition = {
  type: ConditionType['time'];
  tableUuid: string;
  columnUuid: string;
  operator: string;
  value: number;
  unit: TimeComparisonUnit;
  editable: boolean;
};

export type TimeBetweenCondition = {
  type: ConditionType['time'];
  tableUuid: string;
  columnUuid: string;
  operator: 'between';
  values: string[] | null;
  editable: boolean;
};

export type TextComparisonCondition = {
  type: ConditionType['text'];
  tableUuid: string;
  columnUuid: string;
  operator: TextComparisonOperator;
  value: string;
  editable: boolean;
};

export type NumberComparisonCondition = {
  type: ConditionType['num'];
  tableUuid: string;
  columnUuid: string;
  operator: NumberComparisonOperator;
  value: string;
  editable: boolean;
};

export type BooleanComparisonCondition = {
  type: ConditionType['bool'];
  tableUuid: string;
  columnUuid: string;
  value: BooleanComparisonValue;
  editable: boolean;
};

export type OrderBy = {
  tableUuid: string;
  columnUuid: string;
  order: Order;
};
